<template>
  <div>
  Hello ARJS {{ $route.params.name }}
  </div>
</template>

<script>
export default {


}
</script>

<style>

</style>