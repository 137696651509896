<template>
  <div id="active-reports" class="active-reports" style="height: 100vh" v-if="gcsSignInSheetTemplate != ''">
    <!-- <GcArViewer ref="control" 
            viewMode="paginated"
            v-bind:zoom="reportZoom"
            v-bind:availableExports = "['pdf', 'xlsx']"
            v-on:report-loaded="onReportLoaded"
            v-on:document-loaded="onDocumentLoaded"/> --> 
    <GcArViewer ref="reportViewer"></GcArViewer>
  </div>
</template>
<script>
import Vue from 'vue';
// import '@grapecity/activereports';
import { Viewer as ReportViewer} from '@grapecity/activereports-vue';
import "@grapecity/activereports/styles/ar-js-ui.css";
import "@grapecity/activereports/styles/ar-js-viewer.css";

// import service from '../services.js';
// import {PageReport} from "@grapecity/activereports/core";
import { Core } from "@grapecity/activereports"; 
Core.setLicenseKey("reports.callsteward.ca|reports.callsteward.com|reports.callsteward.net,516284612545651#B1mFfNFUerh6ca3UarAHWyczVNB5MwoWMadXRUZGepBFarxGStNkQxNFNmFEMvhEWCl4dJFHUNBlVJpVYIJ7YJZ6KFVTbstUbENFV5ADR8VlY8J4aIJDcqxmNLhUcmdkMUxUaBdnVq34MqF7dvITeZpGdqFnMMpXOrN5ZOdVMaZFa7ljSBJFcwoEZDtSQysST9gHVUJnSpFDMUxmeVlnMHl7RyQDOItkQ4kzZllUdnVnQ6U7SzhUdBBjNBZVRxd5S5JXQWpkNBFGMwdneOVlN9ZzT43UMRljcNJzLXJ5QjVVZrp6bXZ5bMVXNqhUUsRnVtFXZtp4RuZHWlZUWyokUVlGODR4ZylGSvlXZkNmI0IyUiwiI6QzQ9MjM4IjI0ICSiwSNycjN4ETO6kTM0IicfJye&Qf35VfiQlRWllI0IyQiwiI4YFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsISM4YTMzADI5ITNwMjMwIjI0ICdyNkIsICdl9mLkJXY7VGdzxGbhNmLzRncvBXZyxSbvNmLkJXY7VGdzxGbhNmLzRncvBXZyxSYj9CZyF6dlR7csxWYj9yc4J7bwVmciojIz5GRiwiIkJXY7VGdTBCbsF6QiojIh94QiwiIxUjN5QTNyEjN4gjM6ETNiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TU4ZVea3GZkFEdXVGcvRjaYJUZE9mb59UTRNlT6gVRsVjYpBzKzYEe0tkQyk4SSpkRjpENXRTSyVlS4gTW8NmcaZTTJR7L8l5vh");
import {mapGetters, mapActions} from 'vuex';

export default{
    name: 'active-reports',
    components: {
        GcArViewer: ReportViewer
    },

    data: function () {
        return {
            gcsSignInSheetTemplate:'',
            dataSource: ''
        };
    },
    
    computed: {
        ...mapGetters({}),

        reportZoom(){
            if(window.innerWidth>1367){
                return 150;
            }
            return 100;
        }
    },
   
    async mounted(){
        // $('#active-reports').css('height', window.innerHeight);
        var htmlBtnIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="24" height="24"><path class="gc-icon-color--text" d="M19 26v2c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h10v6c0 1.1.9 2 2 2h5v2H6c-1.7 0-3 1.3-3 3v7c0 1.7 1.3 3 3 3h13zM13 3v6c0 .6.5 1 1 1h5l-6-7zM6 14c-1.1 0-2 .9-2 2v7c0 1.1.9 2 2 2h23c1.1 0 2-.9 2-2v-7c0-1.1-.9-2-2-2H6zm23 8v1h-5v-7h1v6h4zm-15-5v6h1v-6h2v-1h-5v1h2zm-4 2v-3h1v7h-1v-3H7v3H6v-7h1v3h3zm10.5 0L19 16h-1v7h1v-5l1 2h1l1-2v5h1v-7h-1l-1.5 3z" fill-rule="evenodd" clip-rule="evenodd" /></svg>';    
        this.gcsSignInSheetTemplate = atob(this.$route.params.url);
        this.dataSource = this.$route.query.data_source&&this.$route.query.data_source!=''?atob(this.$route.query.data_source):'';
        await this.onLoadFromFile(this.gcsSignInSheetTemplate);
        // console.log(atob(this.$route.params.url),atob(this.$route.query.data_source));
        // console.log(atob(this.$route.query.data_source,this.dataSource));
        // if(this.$route.query.data_source) {
        //     this.dataSource = atob(this.$route.query.data_source);
        //     // console.log(atob(this.$route.query.data_source));
        // }
    },
    
    methods: {
        async onLoadFromFile(url) {
            if(this.dataSource&&this.dataSource!='') {
                let data = await this.loadData();
                console.log(data);
                await this.getAlienAPI(url).then(res=>{
                    console.log(res.data);
                    res.data.DataSources[0].ConnectionProperties.ConnectString = "jsondata=" + JSON.stringify(data);
                    // res.data.DataSets[0].Fields = this.dataSetFields;
                    // res.data.DataSets[0].Query.CommandText = "jpath=$.*";
                    this.$refs.reportViewer
                    .Viewer()
                    .open(res.data);  
                })
            }else {
                // console.log(url);
                await this.getAlienAPI(url).then(res=>{
                    console.log(res.data);
                    this.$refs.reportViewer
                    .Viewer()
                    .open(res.data);  
                })
            }
        },

        async getAlienAPI(url) {
            return await new Promise((resolve, reject) => {
                Vue.axios.get(url).then((response) => resolve(response))
                    .catch((error) => reject(error))
            });
        },

        async loadData() {
            const headers = new Headers();
            let data = null;
            // console.log(this.dataSource);
            // await fetch('https://callsteward.com/storage/app/public/active_reports/33d066b0-574a-11eb-ba70-a13445df80dd/report.json')
            await fetch(this.dataSource)
            .then(async (response) => await response.json())
            .then((json) => { 
                // console.log(json);
                data = json;
            });
            return data;
        }
            
        // loadReport(url){
        //     service.getAlienAPI(url).then(res=>{
        //         console.log(res.data);
        //         this.getViewer().open(res.data);
        //     })
        // },
    }


}
</script>
